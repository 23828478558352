import React from "react"
import SEO from "../components/seo"
import { FaArrowRight } from "react-icons/fa"

import "../scss/plankton.scss"
import "../scss/companies.scss"

import HeaderLogoImg from "../assets/img/plankton/logo.png"
import PlanktonFeatures from "../assets/img/plankton/plankton.png"
import PeopleImg from "../assets/img/plankton/people.png"
import Fish1 from "../assets/img/plankton/fish1.png"
import Fish2 from "../assets/img/plankton/fish2.png"
import SingleForm from "../components/singleForm"

const PlanktonPage = () => {
  return (
    <div>
      <SEO title="Plankton" keywords={[`youpal`, `youpal group`, `plankton`]} />
      <div className="youMain planktonMain">
        <section className="container sectionHeader" id="planktonHeader">
          <div className="col">
            <img className="headerLogo" src={HeaderLogoImg} />
            <div className="internalMainHeader bugP">
              <h2 className="internalNarrowCol">
                ideas. <br />
                whalesized.
              </h2>
            </div>
            <p className="internalNarrowCol headingSubtitle">
              you know where you want to go. we’re here to get you there.
            </p>
          </div>
        </section>

        <div className="mainBody">
          <section className="container">
            <div className="row fishContainer">
              <div className="col fishDiv">
                <img className="fish1" src={Fish1} />
              </div>
              <div className="col betweenFishes">
                <h3 className="internalNarrowCol">
                  we’re plankton. <br />a creative ecosystem.
                </h3>
                <p className="internalNarrowCol">
                  we’re the creators of ideas and the builders of concepts. <br />
                  branding & PR, marketing & social. <br />
                  business development. <br />
                  we will not only disturb you, we will shatter your entire
                  existence. <br />
                  welcome.
                </p>
              </div>
              <div className="col fishDiv fish2Div">
                <img className="fish2" src={Fish2} />
              </div>
            </div>
          </section>

          <section className="container internalSection internalStuff">
            <div className="row TwoCol">
              <div className="col">
                <img className="sideImg" src={PlanktonFeatures} />
              </div>
              <div className="col">
                <div className="col sideContent">
                  <h3 className="text-left">some stuff we do</h3>
                  <h4>branding</h4>
                  <p>
                    around the world to grow - all from building upon business
                    culture, help out with the dire need of employer branding as
                    well as implement those ideas
                  </p>
                  <p>we do strategy. we do implementation of such</p>
                  <div className="buttonArea plankton">
                    <a href="https://plankton.cc/" target="_blank">
                      <button type="button" className="btn btn-primary px-4">
                        <span>Dive Into The Ocean</span>
                        <FaArrowRight style={{ fill: "white" }} />
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="container exploreSection">
            <div className="row">
              <div className="col sideContent">
                <h3>marketing & social</h3>
                <p className="mb-5 exploreSectionText">
                  we’re helping a vast amount of organisations, industries and
                  people to grow, to sell more and to position themselves as the
                  leaders within their field. we do so by feeding and feeding
                  and feeding until we helped them grow bigger than the size
                  they first thought was possible.
                </p>
              </div>
            </div>

            <div className="row mt-5">
              <div className="offset-md-2 col-md-3 text-md-left">
                <h4>conceptualisation</h4>
                <p className="text-left">
                  coming up with fantastic ideas is not something that just
                  happens, it takes a lot of effort and strength to push through
                  all the noise that’s out there. so much noise.
                </p>
              </div>

              <div className="offset-md-2 col-md-3 text-md-left">
                <h4>automation & data</h4>
                <p className="text-left">
                  smart tools and process of automation for simulating, planning
                  and executing as well as analysing afterwards. we’re data
                  driven
                </p>
              </div>
            </div>
          </section>
        </div>

        <section className="container internalSection">
          <div className="row TwoCol">
            <div className="col align-self-center">
              <div className="col sideContent">
                <h3 className="mb-5">
                  Meet the people on the ocean currents who know where you wanna
                  go
                </h3>
                <div className="collaborationListPlankton collaborationList">
                  <h4>Benjamin Gredeson</h4>
                  <p>CEO and Founder</p>
                  <h4>Paula Lema</h4>
                  <p>Project Manager</p>
                  <h4>Deirdre Spratt</h4>
                  <p>Global Strategist</p>
                  <h4>Vukasin Obradovic</h4>
                  <p>Social & Digital</p>
                </div>
              </div>
            </div>
            <div className="col">
              <img className="sideImg" src={PeopleImg} />
            </div>
          </div>
        </section>

        <section className="container">
          <div className="row contactBox">
            <SingleForm resourceUrl="https://ma.youpal.se/form/generate.js?id=23" selector="contact-us-mautic-company-form" text="If you want to define the ocean creatures and currents, let us know and we will let you dive with us" />
          </div>
        </section>
      </div>
    </div>
  )
}

export default PlanktonPage
